exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-board-js": () => import("./../../../src/pages/about/board.js" /* webpackChunkName: "component---src-pages-about-board-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-adoption-js": () => import("./../../../src/pages/adoption.js" /* webpackChunkName: "component---src-pages-adoption-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-donate-with-cryptocurrency-js": () => import("./../../../src/pages/donate-with-cryptocurrency.js" /* webpackChunkName: "component---src-pages-donate-with-cryptocurrency-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-free-consultations-js": () => import("./../../../src/pages/free-consultations.js" /* webpackChunkName: "component---src-pages-free-consultations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-embryo-adoption-stories-js": () => import("./../../../src/pages/personal-embryo-adoption-stories.js" /* webpackChunkName: "component---src-pages-personal-embryo-adoption-stories-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-support-nedc-js": () => import("./../../../src/pages/support-nedc.js" /* webpackChunkName: "component---src-pages-support-nedc-js" */),
  "component---src-pages-training-wheels-js": () => import("./../../../src/pages/training-wheels.js" /* webpackChunkName: "component---src-pages-training-wheels-js" */),
  "component---src-pages-world-js": () => import("./../../../src/pages/world.js" /* webpackChunkName: "component---src-pages-world-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/author-template.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

